import React from "react";
import clsx from "clsx";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";

import "./PressPreviewItem.scss";

const PressPreviewItem = ({ className, article, slug, ...props }) => {
  const { title, created_at } = article;
  return (
    <Link className={clsx("press-preview-item", className)} to={`/press/${slug}/`} {...props}>
      <time className="press-preview-item__date">{created_at}</time>
      <h2 className="t-title-2 press-preview-item__title">{title}</h2>
      <a className="press-preview-item__link t-light-gray">Read More</a>
      <hr className="press-preview-item__divider" />
    </Link>
  );
};

export default PressPreviewItem;
