import React from "react";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import clsx from "clsx";

import "./PageButton.scss";

const PageButton = ({ className, to, children }) => {
  return to ? (
    <Link className={clsx("page-button t-sm t-bold", className)} to={to} activeClassName="page-button--active">
      {children}
    </Link>
  ) : (
    <div className={clsx("page-button page-button--disabled", className)}>{children}</div>
  );
};

export default PageButton;
