import React from "react";
import { graphql } from "gatsby";
import Layout from "@w3f/gatsby-theme-w3f/src/components/site/Layout";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import PressPreview from "../components/PressPreview";
import Container from "layouts/Container";
import Cta from "components/Cta";

export default function PressPage({ pageContext, data: { page, allPressArticles, footerImage } }) {
  const { seo } = page.frontmatter;
  const meta = seo ? { ...seo } : {}; // In case the seo object is null

  return (
    <Layout footerImage={footerImage}>
      <SEO {...meta} />

      <Container className="container--t-margin-sm container--b-margin-md">
        <PressPreview
          pressArticles={allPressArticles.nodes}
          currentPage={pageContext.currentPage}
          numPages={pageContext.numPages}
        />
      </Container>
      <Container className="container--v-margin-lg">
        <Cta
          title="Read up on our news, research, grants initiatives, and more."
          link={{
            to: "https://medium.com/web3foundation",
            title: "Visit our Medium channel",
          }}
        />
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $language: String!) {
    ...Locales
    page: markdownRemark(fileAbsolutePath: { regex: "//pages/press.md/" }) {
      frontmatter {
        seo {
          ...Seo
        }
      }
    }
    allPressArticles: allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: frontmatter___created_at }
      filter: {
        fields: { langKey: { eq: $language } }
        fileAbsolutePath: { regex: "//press-articles//" }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      nodes {
        html
        frontmatter {
          title
          slug
          created_at(formatString: "dddd, LL")
        }
        fields {
          langKey
          slug
        }
      }
    }
    footerImage: file(name: { eq: "projects-large" }) {
      ...BgImage
    }
  }
`;
