import React from "react";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import { InView, staggerStyle } from "utils/animation";
import PressPreviewItem from "./PressPreviewItem";
import Aside from "elements/Aside";
import ArrowLink from "elements/ArrowLink";
import Pagination from "components/Pagination";

import "./PressPreview.scss";

const PressPreview = ({ pressArticles, currentPage, numPages }) => {
  const { t } = useTranslation();

  return (
    <InView className="press-preview">
      <div className="press-preview__content">
        <h1 className="press-preview__title t-title-1 a-fade-up">{t("Press")}</h1>
        <div className="press-preview__articles">
          {pressArticles.map((article, index) => {
            return (
              <PressPreviewItem
                key={index}
                className="a-fade-up-and-in"
                style={staggerStyle(1 + index * 2)}
                article={article.frontmatter}
                slug={article.fields.slug}
              />
            );
          })}
        </div>
        <Pagination
          className="press-preview__pagination"
          currentPage={currentPage}
          numPages={numPages}
          basePath="/press/"
        />
      </div>

      <div className="press-preview__aside a-fade-up-from-bottom">
        <Aside
          title="Press inquiries"
          content={
            <div>
              <ArrowLink to="mailto:press@web3.foundation" stretched>
                press@web3.foundation
              </ArrowLink>
              {/* TODO: add once PressKit is available
              <ArrowLink to"" stretched>Download press kit</ArrowLink>
              */}
            </div>
          }
        />
      </div>
    </InView>
  );
};

export default PressPreview;
