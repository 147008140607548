import React from "react";
import PageButton from "components/Pagination/PageButton";
import clsx from "clsx";
import ChevronLeft from "/src/images/svgs/chevron-left.svg";
import ChevronRight from "/src/images/svgs/chevron-right.svg";

import "./Pagination.scss";

const Pagination = ({ className, currentPage, numPages, basePath }) => {
  if (numPages <= 1) return null; // No pagination if only one page exists

  // temporary index for (...) button
  let tempIdx = 0;

  return (
    <div className={clsx("pagination", className)}>
      <PageButton
        className="pagination__item"
        to={currentPage > 1 && `${basePath}${currentPage > 2 ? `${currentPage - 1}/` : ""}`}
      >
        <ChevronLeft />
      </PageButton>
      {Array.from({ length: numPages }).map((_, i) => {
        // Always render currentPage, first and last, also always render one before and after currentPage
        if (currentPage - 1 === i || i === 0 || i + 1 === numPages || currentPage === i || currentPage - 2 === i) {
          return (
            <PageButton key={i} className="pagination__item" to={`${basePath}${i > 0 ? `${i + 1}/` : ""}`}>
              {i + 1}
            </PageButton>
          );
        }
        // Don't render (...) button if there was already a previous one rendered.
        if (tempIdx > 0 && tempIdx === i - 1) {
          tempIdx = i;
          return null;
        }
        tempIdx = i;
        return (
          <PageButton key={i} className="pagination__item pagination__dots">
            ...
          </PageButton>
        );
      })}
      <PageButton className="pagination__item" to={currentPage !== numPages && `${basePath}${currentPage + 1}/`}>
        <ChevronRight />
      </PageButton>
    </div>
  );
};

export default Pagination;
